<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('Lesson') }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="chapterForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div v-if="lesson.id" class="lesson-info">
                    <div v-if="lesson.createdAt">
                      {{ $t('Created on') }}:
                      {{lesson.createdAt | formatLocaleDate}}
                    </div>
                    <div v-if="lesson.author">
                      {{ $t('Created from') }}:
                      {{lesson.author.firstName}} {{lesson.author.lastName}}
                    </div>
                    <div v-if="lesson.lastUpdatedBy">
                      {{ $t('Last modified from') }}:
                      {{lesson.lastUpdatedBy.firstName}} {{lesson.lastUpdatedBy.lastName}}
                    </div>
                    <div v-if="lesson.updatedAt">
                      {{ $t('Last modified on') }}:
                      {{lesson.updatedAt | formatLocaleDate}}
                    </div>
                    <div v-if="lesson.id">
                      ID: {{lesson.id}}
                    </div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="lesson.title"
                      :label="$t('Title')"
                      required
                      :rules="chapterTitleRules()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <tiptap-vuetify
                      v-model="lesson.description"
                      :extensions="extensions"
                  />
                </v-col>
                <v-col cols="12">
                  <image-input
                      v-model="lesson.image"
                      :label="$t('Title image')"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="lesson.courseLink"
                      :label="$t('Link to course contents')"
                      :append-icon="'mdi-window-close'"
                      @click:append="lesson.courseLink=''"
                  ></v-text-field>
                </v-col>
                <v-col v-if="content" cols="12">
                  <v-card-title>{{ $t('Content') }}:
                    <v-btn
                        icon
                        color="red"
                        @click="lesson.content=[]"
                    >
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    {{ $t('Name') }}: {{content.originalName}}<br/>
                    {{ $t('Size') }}: {{(content.size / (1024*1024)).toFixed(2)}}MB<br/>
                    {{ $t('Type') }}: {{content.mimeType}}<br>
                    {{ $t('Id') }}: {{content.id}}<br/>
                    {{ $t('Path') }}: {{content.path}}<br/>
                    <template v-if="content.sha256sum"> {{ $t('Checksum') }}: {{content.sha256sum}}<br></template>
                  </v-card-text>
                </v-col>
                <v-col cols="12">
                  <file-upload @uploaded="fileUploaded" :unzipContent="true" @started="uploadStarted"/>
                  <v-checkbox :label="$t('User may download content')" v-model="lesson.isContentDownloadable"></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-select
                      v-model="lesson.badge"
                      :items="badges"
                      item-text="name"
                      item-value="id"
                      :label="$t('Badge')">
                    <template v-slot:selection="data">
                      <v-row>
                        <v-col cols="6">{{data.item.name}}</v-col>
                        <v-col cols="6"><img class="badge-image" :src="getFileUrl(data.item.image.path)"></v-col>
                      </v-row>
                    </template>
                    <template v-slot:item="data">
                      <v-row>
                        <v-col cols="6">{{data.item.name}}</v-col>
                        <v-col cols="6"><img class="badge-image" :src="getFileUrl(data.item.image.path)"></v-col>
                      </v-row>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12">

                  <v-expansion-panels
                      v-model="uploadPanel"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header>{{ $t('Files') }}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col class="text-right mb-6">
                            <v-btn
                                rounded
                                color="primary"
                                dark
                                @click="addUpload"
                            >
                              <v-icon left>mdi-plus</v-icon>
                              {{ $t('File') }}
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-data-table
                            :headers="uploadHeaders"
                            :items="lesson.uploads"
                            :items-per-page="10"
                            class="elevation-1"
                            sort-by="originalName"
                            :sort-desc="true"
                        >
                          <template v-slot:item.actions="{ item }">
                            <v-icon
                                small
                                @click="deleteUpload(item)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                      :no-resize="true"
                      rows="2"
                      v-model="lesson.comment"
                      :label="$t('Comment')"
                  />
                </v-col>
                <v-col v-if="lesson.assignedTo"  cols="12">
                  <div class="subtitle-1">{{ $t('The lesson is assigned to following chapters') }}:</div>
                  <div v-html="getChapterTitles()"/>
                 </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
              :disabled="isUploading"
              color="blue darken-1"
              text
              @click="saveLesson"
          >
            {{ $t('Save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <course-upload-dialog ref="courseUploadDialog"/>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"/>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import FileUpload  from "@/components/FileUpload";
import Snackbar from "@/components/Snackbar";

import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
import ImageInput from "@/components/ImageInput";
import { getFileUrl } from "@/utils/common"
import CourseUploadDialog from "@/components/CourseUploadDialog.vue";
export default {
  name: 'LessonEditDialog',
  components: {CourseUploadDialog, Snackbar, ConfirmDialog, TiptapVuetify, FileUpload, ImageInput },
  data() {
    return {
      badges: [],
      showDialog: false,
      lesson: {},
      saveSuccess: false,
      isLessonTitleTaken: false,
      parentId: 0,
      snackbarSuccess: {
        text: this.$t('Lesson saved successfully'),
        icon: 'mdi-check-circle',
        color: 'success'
      },
      dragging: false,
      uploadPanel: true,
      uploadHeaders: [
        {
          text: this.$t('Filename'),
          align: 'left',
          sortable: true,
          value: 'file.originalName',
        },
        { text: this.$t('Description'),
          value: 'description',
          sortable: true,
        },
        {
          text: this.$t('Actions'),
          value: 'actions',
          align: 'end'
        }
      ],
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      isUploading: false
    }
  },
  methods: {
    getFileUrl,
    async addUpload() {
      this.$refs.courseUploadDialog.show().then((upload) => {
        console.log("File uploaded", upload)
        const id = this.lesson.uploads.length ? Math.max(...this.lesson.uploads.map(upload => upload.id)) + 1 : 1
        this.lesson.uploads.push({...upload, id})
      })
    },
    async deleteUpload(upload) {
      await this.$refs.confirmDialog.show({
        title: this.$t('Remove file'),
        text: this.$t('Do you want to remove the following file?')+ "<b>"+(upload.file ? upload.file.originalName : '' )+"</b>b>",
        confirm: this.$t('remove')
      })
      this.lesson.uploads = this.lesson.uploads.filter((u) => upload.id !== u.id)
    },
    async create() {
      this.lesson = {}
      this.badges = await APIService.getBadges()
      this.showDialog = true
    },
    async edit(lesson) {
      this.lesson = lesson
      this.badges = await APIService.getBadges()
      this.showDialog = true
    },
    close() {
      this.lesson = {}
      this.$refs.chapterForm.resetValidation()
      this.showDialog = false
    },
    getChapterTitles() {
      return this.lesson.assignedTo ? this.lesson.assignedTo.map(lesson => lesson.title).join(', ') : ''
    },
    async saveLesson() {
      console.log("Save Lesson")
      if (!this.$refs.chapterForm.validate()) {
        return
      }
      if(! this.lesson.uploads) this.lesson.uploads = [];
      this.lesson.uploads = this.lesson.uploads.map((upload) => ({
        ...upload.file.id ? {file: upload.file.id} : {},
        path: upload.file.path,
        description: upload.description,
        uploadedBy: this.user.id,
      }))
      await APIService.saveLesson(this.lesson)
      this.$refs.snackbar.show(this.snackbarSuccess)
      this.$emit('updated')
      this.close()
    },
    requiredRule (fieldName) {
      return [value => !!value || `${fieldName} `+this.$t('mandatory')]
    },
    chapterTitleRules() {
      return [
        value => !!value || this.$t('Title mandatory'),
        !this.isLessonTitleTaken || this.$t('Title aready taken')
      ]
    },
    fileUploaded(fileMeta) {
      console.log("File uploaded", fileMeta)
      this.isUploading = false
      this.lesson = {...this.lesson, content: [{file: fileMeta, path: fileMeta.path}] }
    },
    uploadStarted() {
      this.isUploading = true
    }
  },
  computed: {
    ...mapState(['user']),
    content() {
      return this.lesson.content ? this.lesson.content.length > 0 ? this.lesson?.content[0].file : null : null
    }
  },
  watch: {
    "chapter.name"() {
      this.isLessonTitleTaken = false
    }
  }
}
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.badge-image {
  max-width: 100px;
  padding: 4px;
}
</style>
