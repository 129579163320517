<template>
  <div>
    <h1>{{ $t("Chapter") }}</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('Search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">
            <v-btn
              rounded
              color="primary"
              dark
              @click="createChapter"
            >
              <v-icon left>mdi-plus</v-icon>
              {{ $t('Chapter') }}
            </v-btn>
            <v-btn
                rounded
                color="success"
                dark
                @click="createCSV"
                style="margin-left:10px"
            >
              <v-icon left>mdi-download</v-icon>
              CSV
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="chapters"
        :search="search"
        :items-per-page="itemsPerPage"
        @update:options="updateOptions"
        class="elevation-1"
        :hide-default-footer="chapters.length < 5"
        :footer-props="{
          'items-per-page-text':$t('Rows per page'),
          'items-per-page-options': [15, 50, 100, -1]
        }"
      >
        <template v-slot:item:name="{  }">
          <v-avatar
              color="primary"
              size="56"
          ></v-avatar>
        </template>
        <template v-slot:item.author="{ item }">

          <v-avatar
              v-if="item.author"
              class="mr-1"
              style="color: white; font-weight: bolder;"
              color="warning"
              size="35"
              small
          >{{uppercaseFirstLetter(item.author.firstName)}}{{uppercaseFirstLetter(item.author.lastName)}}</v-avatar>
          <template v-if="item.author">{{item.author.firstName}} {{item.author.lastName}}</template>
        </template>
        <template v-slot:item.createdAt="{ item }" class="ml-0">
          {{ item.createdAt | formatLocaleDate }}
        </template>
        <template v-slot:item.updatedAt="{ item }" class="ml-0">
          {{ item.updatedAt | formatLocaleDate }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <chapter-edit-dialog ref="chapterEditDialog" @updated="fetchChapters"/>
    <confirm-dialog ref="confirmDelete"/>
    <Spinner v-if="showLoader" class="s3m-spinner" name="circle" color="#008bc4" />
  </div>
</template>

<script>
import ChapterEditDialog from '@/components/ChapterEditDialog'
import ConfirmDialog from '@/components/ConfirmDialog'
import APIService from '@/services/APIService'
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";
import {escapeForCSV} from "@/utils/common";
import Papa from "papaparse";

export default {
  name: 'ChapterList',
  mixins: [vuetifyTableMixin],
  components: { ChapterEditDialog, ConfirmDialog },
  data () {
    return {
      search: '',
      chapters: [],
      showLoader: false
    }
  },
  computed:{
    headers(){ return [
      {
        text: this.$t('title'),
        align: 'start',
        sortable: true,
        value: 'title',
        width: 400
      },
      {
        text: this.$t('Author'),
        value: 'author',
        sortable: true
      },
      {
        text: this.$t('Created on'),
        value: 'createdAt',
        sortable: true
      },
      {
        text: this.$t('Zuletzt geändert am'),
        value: 'updatedAt',
        sortable: true
      },
      {
        text: this.$t('Actions'),
        value: 'actions',
        sortable: true,
        align: 'end'
      }
    ]},
  },
  methods: {
    createChapter () {
      this.$refs.chapterEditDialog.create()
    },
    async createCSV(){
      let csvData = [];
      csvData.push([]);
      /*
      for(let i=0; i < this.headers.length; i++){
        csvData[0].push(this.headers[i].text);
      }*/

      let headers = [
        "Titel",
        "Beschreibung",
        "Autor",
        "Zuletzt geändert von",
        "Erstellt am",
        "Zueltzt geändert am",
        "ID",
        "Kommentar"
      ];
      for(let i=0; i < headers.length; i++){
        csvData[0].push(headers[i]);
      }

      for(let i=0; i < this.chapters.length; i++){
        let current = this.chapters[i];

        let desc = current.description ? current.description : "";
        desc = escapeForCSV(desc);

        let authorString = "";
        authorString += (current.author && current.author.firstName) ? current.author.firstName : "";
        authorString += (authorString.length > 0) ? " " : "";
        authorString += (current.author && current.author.lastName) ? current.author.lastName : "";
        authorString += (authorString.length > 0) ? " " : "";
        authorString += (current.author && current.author.email) ? (" - " + current.author.email) : "";

        let row = [current.title ? current.title : "", desc, authorString, current.lastUpdatedBy, current.createdAt, current.updatedAt, current.id, current.comment];
        csvData.push(row);
      }
      let csv = Papa.unparse(csvData);

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'kapitel.csv';
      anchor.click();
    },
    editItem (chapter) {
      APIService.getChapter(chapter).then(chapter => {
        this.$refs.chapterEditDialog.edit(chapter)
      })
    },
    async deleteItem (chapter) {
      await this.$refs.confirmDelete.show({
        title: this.$t('Remove chapter'),
        text: this.$t('Do you want to remove this chapter?')+" "+chapter.title,
        confirm: this.$t('remove')
      })
      await APIService.deleteChapter(chapter)
      this.chapters = await APIService.getChapters()
    },
    pagination(data) {
      console.log("Pagination", data)
    },
    pageCount(data) {
      console.log("PageCount", data)
    },
    uppercaseFirstLetter(string) {
      return string ? string.charAt(0).toUpperCase() : ''
    },
    async fetchChapters() {
      this.showLoader = true;
      this.chapters = await APIService.getChapters();
      this.showLoader = false;
    }
  },
  async beforeRouteEnter(to, from, next) {
    const chapters = await APIService.getChapters()
    next(vm => vm.chapters = chapters)
  },
  async beforeRouteUpdate(to,from,next) {
    this.chapters = await APIService.getChapters()
    next()
  }
}
</script>

<style scoped>

</style>
